export default {
  formSteps: {
    0: {
      question: 'What would you like to do the most?',
      choices: {
        0: 'Go on a spontaneous vacation for a couple of days with nothing planned in advance',
        1: 'Have great conversations with close friends while cooking a meal together',
        2: 'Throw a surprise party for a loved one',
        3: 'Spend a relaxing afternoon in the backyard of my house',
        4: 'Test my limits in an extreme sport',
      },
      advices: {
        0: '<p><strong>Your 1st Strength: Independence</strong><br>People with this kind of strength are self directed. Their lives are filled with independent decisions. It takes inner strength and courage not to be <strong>easily thrown off track</strong> when new things come your way - and you have what it takes! Nevertheless, the thought of having a baby may intimidate you. You might be held back with the thought of losing some of your self-determination.<br>At the same time, being independent includes the ability <strong>to make choices</strong> in the midst of constricting responsibilities and challenges. You are given the freedom to use this ability in a way that will make you <strong>look back on your decisions without regrets</strong>.</p>',
        1: '<p><strong>Your 1st Strength: Empathy</strong><br>This ability allows you to sense what others are going through and what they are feeling. On the same note, your <strong>empathy</strong> enables you to provide emotional support to others. You know how to give warmth and comfort.<br>Ironically, you might be finding it difficult to understand your own emotions right now. Perhaps you feel like the challenge of a pregnancy is a number too big for you.But given your sensitive intuition, you will know <strong>what would help you the most</strong> when difficult situations arise.</p>',
        2: '<p><strong>Your 1st Strength: Passion</strong><br>When you get down to business, there’s no stopping you - you dedicate yourself to people and assignments with passion. You’re able to stir courage in others and put things in motion.<br>You may be concerned that the challenges of a pregnancy could snuff out your fire, robbing your life of its fullness…<br><strong>Let your determination do its work</strong>. This situation will then cause your fire to <strong>burn even brighter</strong>.</p>',
        3: '<p><strong>Your 1st Strength: Serenity</strong><br>As someone who appreciates serenity, you most likely have a calming effect on others. Your composure and thoughtfulness help those around you to also feel relaxed.<br>You might be concerned that the challenges in this pregnancy will upset this equilibrium.<br>Instead, use your strengths to <strong>remain calm</strong> in the midst of this situation. <strong>Taking one careful step at a time</strong> will allow you to discover your very own path. Journeying down this road will cause you to flourish as a person.</p>',
        4: '<p><strong>Your 1st Strength: Courage</strong><br>You are game for any adventure or challenge. Facing the unknown makes you push yourself beyond your limits. You are willing to endure hardship, <strong>stepping out of your comfort zone</strong>. By doing so, you <strong>inspire others</strong> to try something new.<br>You might be concerned that the pregnancy will force you to forego some possible adventures and, thereby, cause you to lose part of who you are…<br>You were made for such a time as this! You are the one who is experienced in overcoming seemingly insurmountable hurdles. It is simply in your nature to be undeterred by such challenges and to <strong>face your fears</strong>. Your strength lies in turning any difficulty into an exciting adventure.</p>',
      },
    },
    1: {
      question: 'You just found out that you have to move to another city because of your job - what is your initial reaction?',
      choices: {
        0: 'My mind is flooded by a checklist of things I have to get done: cancel the lease on my apartment, look for a new place to live, pack all my things, etc.',
        1: "I'm really hit by the news and need some time to sort out my thoughts.",
        2: 'I try to take a positive approach to the news - even if I need some time to digest it.',
      },
      advices: {
        0: '<p><strong>Your 2nd Strength: Skills</strong><br>Thinking things through, weighing out options and finding the best solutions are valuable talents that come naturally to you.<br>You use your mind to overcome difficult situations by thinking of concrete steps that will help you reach your goal. Your <strong>steady mind</strong> allows you to be unbothered by obstacles. With this strength, you are able to solve even the <strong>most complex of problems</strong>.</p>',
        1: '<p><strong>Your 2nd Strength: Intuition</strong><br>Your intuition and sense of knowing are profound. Your gut feeling guides you and gives you an initial sense of which direction to take.</p>',
        2: '<p><strong>Your 2nd Strength: Balance</strong><br>When difficult situations arise, your <strong>sensitivity and circumspection</strong> serve you well, guiding you to pay close attention to both reasoning and intuition.<br>You use your mind to find possible solutions and to plan the next step — but you are also very aware of your gut intuitions and allow your emotions to play a role in the decision making process. This balance is the perfect recipe for making mature decisions!</p>',
      },
    },
    2: {
      question: 'If I had more time in my day, I would prefer to...',
      choices: {
        0: 'spend more time with the people I love.',
        1: 'pour more time and energy into improving myself and the projects I work on.',
        2: 'do more things I enjoy doing during my free time.',
        3: 'focus more on staying true to who I really am.',
      },
      advices: {
        0: '<p><strong>Your 3rd Strength: Connectedness</strong><br>You are one who loves to give to others. You wouldn’t have it any other way than to be there for those around you and to share your life with them.<br>When caring for someone else, you feel a <strong>deep sense of purpose</strong>. You realize that your greatest satisfaction stems from <strong>looking beyond yourself</strong> and pouring into the lives of others.</p>',
        1: '<p><strong>Your 3rd Strength: Motivation</strong><br>You enjoy putting your skills to use. You take on projects with enthusiasm and mold them into <strong>something meaningful</strong>. To be completely absorbed in a good cause gives you deep fulfillment.<br>You probably agree: Your greatest contentment is found in rising up to a challenge <strong>for the sake of a greater cause</strong>.</p>',
        2: '<p><strong>Your 3rd Strength: Enthusiasm</strong><br>Life is beautiful - and you love basking in its beauty! You search it out from many different angles: in nature, in arts and crafts and in encountering other cultures. <strong>Looking beyond yourself</strong> by being amazed by the world around you in all its detail gives you a deep sense of fulfillment.<br>Your ability to see beauty in the midst of trials takes you to a place of contentment.</p>',
        3: '<p><strong>Your 3rd Strength: Authenticity</strong><br>You are satisfied by what you see in the mirror, because you have a sense of pride in who you are and what you do. You are clearly an <strong>authentic individual</strong> who holds <strong>strong values</strong> and is not afraid to let her opinion be known.<br>You may have already experienced that staying true to yourself in a challenging situation will often lead to personal growth and result in a sense of fulfillment.</p>',
      },
    },
    3: {
      question: 'If you woke up in the middle of the night filled with worries, who would be the first person you wake up and talk to?',
      choices: {
        0: 'My partner',
        1: 'My mom or dad',
        2: 'One of my siblings or close relatives',
        3: 'One of my friends',
        4: "I wouldn't do that with anyone I know",
      },
      advices: {
        0: '<p><strong>Your 4th Strength: Partnership</strong><br>Your partner is your ultimate support system - you feel comfortable sharing your ups and downs with him. Together you can tackle any obstacle and make the best of things. You are able to <strong>encourage one another and build each other up</strong> when challenges arise.<br>Our desire is that this pregnancy would be a catalyst in molding an already strong relationship into an <strong>even closer bond</strong>. Side by side, you will be able to enjoy the amazing fruit that stems from the kind of love that puts others first.</p>',
        1: '<p><strong>Your 4th Strength: Parental Support</strong><br>Sometimes it is good to know that your parents are behind you - even if you are already an adult. They have a wealth of experiences for you to glean from. At this point in time, their <strong>support</strong> and <strong>advice</strong> are invaluable.<br>This present situation can also deepen your relationship with your parents. Having walked this road before you, they understand the thoughts and emotions that arise when expecting a baby.</p>',
        2: '<p><strong>Your 4th Strength: Strong Family Ties</strong><br>Your extended family forms a network that supplies <strong>invaluable support</strong> throughout all of life’s changes. These people have known you your entire life and would certainly be there for you right now. You can fall back on them to lend a helping hand and a listening ear.<br>Our desire for you is that this support base will free you up to find the path that is just right for you.</p>',
        3: '<p><strong>Your 4th Strength: Friendships</strong><br>"Faithful friends don’t wait for the storm to pass. They dance with you in the rain." You can identify with this saying, because you know your friends always have your back.<br>These loyal companions will turn even the most challenging journey into an adventure. Walking this road together is an opportunity for growth.</p>',
        4: '<p><strong>Your 4th Strength: Seeking Advice</strong><br>Rather than retreating into your shell, you actively pursued finding solutions, which has brought you to our website. We would love to be there for you and help you find the path that is just right for you.<br>Feel free to check out all our <a href="https://www.profemina.org/en/node/1652">resources</a>. You may like our <a href="https://app.profemina.org/en/problems">Solutions Finder Test</a>.</p>',
      },
    },
    4: {
      question: 'This is how I overcame problems in the past:',
      choices: {
        0: 'I tried to see the situation in a positive light',
        1: 'I never lost sight of my goal',
        2: 'By getting help from others',
        3: 'By taking time to think',
        4: 'Through my fundamental trust in life',
        5: "I don't have a special way of doing things, it just happened to always work out",
      },
      advices: {
        0: '<p><strong>Your 5th Strength: Optimism</strong><br>You are able to dance in the rain, rather than wait for the sun to shine again. This <strong>positive way of embracing life</strong> is extraordinary and invaluable. It will give you the courage you need to face this present unsettling situation. You know that, if you look hard enough, you will able to see the silver lining.<br>Our desire for you is that you would discover how, even in these unexpected circumstances, everything can work together for your good.</p>',
        1: '<p><strong>Your 5th Strength: Perseverance</strong><br>Having a goal in mind helps you to face life’s challenges, because you are <strong>looking beyond</strong> the obstacles. You probably already experienced how persevering through trials made you stronger.<br>This goal oriented endurance will serve you well as you seek the path that is right for you. Here are some questions you might want to ask yourself: Which choice would lead to my greatest personal growth? How could these unexpected circumstances be transformed into something good?</p>',
        2: '<p><strong>Your 5th Strength: Open Mindedness</strong><br>You firmly believe the saying, “a sorrow shared is a sorrow halved.” You understand how soothing it is to have someone to share your worries and concerns with. Verbalizing your thoughts brings clarity and helps you see things from a different perspective.<br>You have grasped that asking for others to walk alongside you is a sign of strength and not of weakness.<br>Our desire for you is that you would welcome the advice and support of others in this present situation. By doing so, you may discover the path that is opening up before you.</p>',
        3: '<p><strong>Your 5th Strength: Stillness</strong><br>Taking time to be still is your source of strength. This is especially true when you are flooded by thoughts and emotions in the midst of difficult circumstances, because you are allowing your troubled heart to find solace. Once you become still, you are able to discern what is really important.<br>This may help you determine the best plan of action for your pregnancy. Being still will allow you to understand your true thoughts and desires and will help you discover the opportunity that may arise from the current challenge.</p>',
        4: '<p><strong>Your 5th Strength: Confidence</strong><br>Your life is marked by a confidence that even hard things happen for a reason and that all things work together for good. Despite all difficulties, you have not let go of this belief. You have probably already experienced situations in which good has come from bad.<br>Right now, this awareness can help you remain confident, enabling you to make the best out of your current situation.</p>',
        5: '<p><strong>Your 5th Strength: Master in the Art of Living</strong><br>It is obvious that you are a strong and courageous person. Without quite understanding your own source of strength, you have always been able to master life’s challenges. Your accomplishments speak for themselves.<br>Looking back will help you draw fresh confidence that you will be able to handle any problems involving the pregnancy. Taking each hurdle well will allow you to grow as a person.</p>',
      },
    },
  },
};
