const finalQuestion = 'The most helpful thing right now would be:';

const finalChoicesGeneral = {
  0: 'a caring person to talk to',
  1: 'time to myself',
  2: 'a plan',
  3: 'my partner changing his mind about the baby',
  4: 'practical or financial support',
};

// Pfad 1: Er will das Kind nicht
// Pfad 8.5: Vergewaltigung
// Path 1: He doesn't want the baby
// Path 8.5: Rape
const finalChoicesException = {
  0: 'a caring person to talk to',
  1: 'time to myself',
  2: 'a plan',
  3: 'practical or financial support',
};

const adviceSomebodyWhoListensToMe = '<p><strong>Where to go from here</strong><br>Do you know a <strong>caring person</strong> whom you trust and whom you would like to confide in? Someone you feel comfortable sharing with?<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';
const adviceTimeForMyself = '<p><strong>Where to go from here</strong><br>Why don’t you take the time to yourself that you so much desire? Taking care of yourself will help you feel more at ease. This is a prerequisite for making sense of your thoughts and emotions.<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';
const advicePlan = '<p><strong>Where to go from here</strong><br>Sometimes, putting your thoughts on paper will bring clarity. Try taking one step at a time - you don’t have to tackle everything at once. Each step may broaden your perspective, opening up new trails of thought.<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';
const adviceSupport = '<p><strong>Where to go from here</strong><br>Since practical and financial support is a common need among women who share your situation, many non profit and government organizations have risen to the challenge. A web search will lead you to numerous local offers. Can you also think of a caring person who you could approach?<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';

const advicePartnerSaysYesGeneral = '<p><strong>Where to go from here</strong><br>It is only natural that you would seek your partner’s support at this point in time.<br><br>Initially, many men find it hard to wrap their minds around becoming a parent. Their first reactions are usually concern and hesitation. Expectant fathers may take a bit longer to embrace this situation wholeheartedly. Even though this may seem unlikely at the moment, most men eventually come around. The majority of skeptics become proud and supportive dads. Both of you will benefit if you pay attention to your innermost thoughts and emotions. Seek to make a choice that is right for you.<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';
// Spezialfall für Pfad 2: es zwischen uns kompliziert ist
// Special Case for Path 2: our relationship is complicated
const advicePartnerSaysYesTree211 = '<p><strong>Where to go from here</strong><br>You indicated that what would help you most right now would be if your partner changed his mind about the baby. It is only natural that you would seek your partner’s support at this point in time. You are in good company. The vast majority of women in your shoes share your sentiment.<br><br>Initially, many men find it hard to wrap their minds around becoming a parent, especially if the relationship is rocky. Their first reactions are usually concern and hesitation. Expectant fathers may take a bit longer to embrace this situation wholeheartedly. Even though this may seem unlikely at the moment, most men eventually come around. The majority of skeptics become proud and supportive dads. Both of you will benefit if you pay attention to your innermost thoughts and emotions. Seek to make a choice that is right for you.<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';
const advicePartnerSaysYesTree212 = '<p><strong>Where to go from here</strong><br>You indicated that what would help you most right now would be if your ex changed his mind about the baby. It is only natural that you would seek your partner’s support at this point in time. You are in good company. The vast majority of women in your shoes share your sentiment.<br><br>Initially, many men find it hard to wrap their minds around becoming a parent, especially if the relationship is complicated. Their first reactions are usually concern and hesitation. Expectant fathers may take a bit longer to embrace this situation wholeheartedly. Even though this may seem unlikely at the moment, most men eventually come around. The majority of skeptics become proud and supportive dads. Both of you will benefit if you pay attention to your innermost thoughts and emotions. Seek to make a choice that is right for you.<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';
// Path 2.3.1 & 2.3.2
// das Kind ist von einem anderen
// the child is by another man
const advicePartnerSaysYesTree23 = '<p><strong>Where to go from here</strong><br>You indicated that what would help you most right now would be if your partner changed his mind about the baby. It is only natural that you would seek your partner’s support at this point in time.<br><br>Initially, many men find it hard to wrap their minds around becoming a parent, especially if the baby is by another man. Their first reactions are usually concern, disappointment, and hesitation. It will probably take some time before he is able to embrace this situation. Both of you will benefit, if you pay attention to your innermost thoughts and emotions. Seek to make a choice that is right for you. This will give him the freedom to embrace his own path, which may lead him to embrace parenthood - He would not be the first to do so!<br><br>While this is a decision that no one can make for you, it is good to have caring people to walk alongside you. We sincerely hope that this digital counseling has already provided some support. Check out our other <a href="https://www.profemina.org/en/node/1574" target="_blank" style="color: #55855d">free resources</a>. You may want to try our <a href="https://app.profemina.org/en/resources" target="_blank" style="color: #55855d">Strengths Finder Test</a>.</p>';

// Text Components / Duplications
const adviceTextBothWays = '<br><br><strong>Considering Both Options</strong><br>Right now you are wondering whether to have an abortion or not. Whichever way you choose, you will feel the effect. Taking the time to think through some of your questions may broaden your view and lead you closer to potential answers.<br>';
const adviceTextBothWaysIntro = 'Imagine you had already made a decision and looking back on it you are able to say: That was a great decision!';

const adviceTextTree1BiggestConcernsOption1 = '<strong>Your Greatest Concern</strong><br>Finding out about the pregnancy was probably hard in and of itself. Your thoughts are racing a thousand miles a minute. In all likelihood, you had pictured becoming pregnant completely differently. Hearing the baby’s father spout out negative comments can put you over the edge.';
const adviceTextTree1BiggestConcernsOption2 = '<strong>Your Greatest Concern</strong><br>Finding out about the pregnancy was probably hard in and of itself. Your thoughts are racing a thousand miles a minute. In all likelihood, you had pictured becoming pregnant completely differently. Hearing the baby’s father spout out negative comments can be cause for alarm.';

const adviceTextTree21BiggestConcern = '<strong>Your Greatest Concern</strong><br>Your relationship has already come to an end - but now you are expecting a child from him. It seems to be the worst possible time to be pregnant. This is pushing you to the edge…<br><br>It has certainly not been easy in the past; perhaps the relationship was in upheaval for quite some time.';
const adviceTextTree22BiggestConcern = '<strong>Your Greatest Concern</strong><br>You have probably been going though a hard time for a while, because of the tensions in your relationship. This alone has been disconcerting.<br><br>Adding a pregnancy to the mix would be overwhelming. ';
const adviceTextTree23BiggestConcern = '<strong>Your Greatest Concern</strong><br>Things may have been tense between you and your partner for a while. Something may have been missing in your relationship and you were looking to another man to fill the void. Perhaps you and your partner had been doing well - nevertheless, you got tangled up with someone else. There is no way to undo this. No matter the exact circumstances, you are now (maybe) pregnant by another man.<br><br>All of a sudden, nothing is the same. Experiencing this situation would flood you with many different thoughts, emotions, concerns and fears. It is completely understandable that this would bring you to your personal limits.';

const adviceTextTree3BiggestConcern = '<strong>Your Greatest Concern</strong><br>This may not seem like the right time for having a baby, for one of many reasons. Finding out about the pregnancy would have been very disorienting, because you had always wanted everything to be well planned out.<br><br>You may feel torn, because you had wanted your child to have the best life possible. You wanted to be able to pour yourself into this little person. That is actually what mothering is all about: desiring what is best for one’s child. It is therefore understandable that you feel uncertain about how to proceed.<br><br>';

const adviceTextBullet1 = '<li>What is your source of strength in times of trouble? Where would be a good place to turn?</li>';
const adviceTextBullet2 = '<li>Looking back on your decision, you will want to know that it was the right thing to do. What are the telltale signs of a good decision? What would you be particularly proud of?</li>';
const adviceTextBullet3 = '<li>What are the telltale signs by which you now that you have made good decision?</li><li>What would you be particularly proud of?</li>';

export {
  finalQuestion,
  finalChoicesGeneral,
  finalChoicesException,
  adviceSomebodyWhoListensToMe,
  adviceTimeForMyself,
  advicePlan,
  adviceSupport,
  advicePartnerSaysYesGeneral,
  advicePartnerSaysYesTree211,
  advicePartnerSaysYesTree212,
  advicePartnerSaysYesTree23,
  adviceTextBothWays,
  adviceTextBothWaysIntro,
  adviceTextTree1BiggestConcernsOption1,
  adviceTextTree1BiggestConcernsOption2,
  adviceTextTree21BiggestConcern,
  adviceTextTree22BiggestConcern,
  adviceTextTree23BiggestConcern,
  adviceTextTree3BiggestConcern,
  adviceTextBullet1,
  adviceTextBullet2,
  adviceTextBullet3,
};
