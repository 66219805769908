export default {
  formSteps: {
    0: {
      question: 'What would be most helpful to you right now?',
      choices: {
        0: 'Organizing my thoughts and getting helpful advice',
        1: 'Knowing what my options are',
        2: 'Having someone there to support me in this situation',
      },
      advices: {
        0: '<p><strong>Organizing My Thoughts</strong><br>Being thrown into the situation of an (unintended) pregnancy is likely to <strong>wreak havoc on your mind</strong> and leave you <strong>grasping for answers</strong>. That kind of reaction is completely understandable! Any pregnancy entails changes, which in turn can lead to feelings of uncertainty or anxiety.<br>Our aim is to help you <strong>gain clarity</strong> and equip you with tools for the journey.</p>',
        1: '<p><strong>Knowing the Options</strong><br>Being informed when facing a decision is a good position to be in. It can be daunting to research legal guidelines for an abortion or to look for pregnancy resources.<br>Our goal is to relieve you of this burden by supplying you with as much information as possible. By <strong>knowing your options,</strong> you will be <strong>better equipped to make an informed decision.</strong>.</p>',
        2: '<p><strong>Someone to Lean on</strong><br>Facing a situation, such as an unintended or difficult pregnancy, can push you over the edge. You probably feel like this is beyond what you can handle. It is only natural to look for someone who would share your burden and lighten your load.<br>Whether you can think of a specific person to approach or not, <strong>you are not alone. We want to come alongside you</strong> by providing the informational resources you need.</p>',
      },
    },
    1: {
      question: 'I am ... weeks pregnant.',
      choices: {
        0: "I don't know yet",
        1: 'Less than 8 weeks',
        2: '8 to 14 weeks',
        3: 'more than 14 weeks',
      },
      advices: {
        0: '<p><strong>The Week of Pregnancy</strong><br>The news of your pregnancy is probably very recent. You may have already made an appointment with your gynecologist or are about to do so. Your doctor will be able to tell how far along you are and how the pregnancy is progressing.<br>You still have time to make a decision. For now, <strong>take a breather</strong> while you gather information. Many women have found that <strong>taking a mental break</strong> was really helpful to them. This may also be your best course of action, <strong>rather than allowing yourself to be put under pressure.</strong></p>',
        1: '<p><strong>The Week of Pregnancy</strong><br>You are early on in your pregnancy. <strong>There is still time</strong> before a decision needs to be made. Take a deep breath!<br>Many women have found it beneficial to take a break from the action at this point. Our desire for you is that you would <strong>not allow yourself to be put under pressure.</strong></p>',
        2: '<p><strong>The Week of Pregnancy</strong><br>Several weeks of pregnancy may have already gone by.<br>You may be close to the cut-off date for an abortion and are sensing that a decision must be made within the coming days or even hours. Try to hold off while you are still feeling unsure. <strong>You are worth more than a quick decision.</strong> Wait for a choice that your heart can be on board with.</p>',
        3: '<p><strong>The Week of Pregnancy</strong><br>You have already passed your first trimester, but still feel unsure about where to go from here. Taking the time and effort to seek solutions is needful and good.<br>Abortion may no longer be an option if you are living in an area where abortion is restricted to the first 12 weeks of pregnancy. This may even come as a relief, since the pressure of making a decision was taken off your plate. You may also experience this as an even greater source of concern. Either way, our goal is to aid you on your path toward solutions that are just right for you.</p>',
      },
    },
    2: {
      question: 'How does the pregnancy affect your daily life?',
      choices: {
        0: 'I try to push thoughts about my pregnancy out of my mind.',
        1: 'I can’t think of anything but the pregnancy.',
        2: 'I think about my pregnancy, but can manage to think of other things as well',
      },
      advices: {
        0: '<p><strong>Thoughts - Where to go from here?</strong><br>So far you have tried not to give much thought to the pregnancy. Desiring not to be consumed by pregnancy related worries and concerns is understandable and even commendable.<br>Nonetheless, the fact that you are now thinking ahead and seeking solutions is equally beneficial.</p>',
        1: '<p><strong>Thoughts - Where to go from here?</strong><br>Pregnancy related worries and concerns feel all encompassing right now. This is sure to be overwhelming and exhausting.<br>Of course it is important to think things through. But reaching a good decision also takes having a <strong>clear mind</strong>. Therefore, <strong>taking mental breaks</strong> is equally needful. You may find it helpful to press pause by choosing to do something you enjoy.</p>',
        2: '<p><strong>Thoughts - Where to go from here?</strong><br>You have struck a good balance in dealing with the pregnancy and all the concerns that accompany it. You raise your concerns, which is normal and important to do; but you do not allow yourself to be overcome by them. You purposefully step away from the constant flood of thoughts in order to <strong>take a deep breath and clear your mind.</strong><br>Not many are able to do that! This will aid you in making a good decision.</p>',
      },
    },
    3: {
      question: 'My first reaction to the pregnancy:',
      choices: {
        0: 'Anxiety',
        1: 'Shock',
        2: 'Joy',
        3: 'Mixed emotions',

      },
      advices: {
        0: '<p><strong>Initial Reaction: Anxiety</strong><br>The initial news of the pregnancy has seemed to <strong>shroud your whole world in darkness</strong>. This perception is completely okay and shared by many other women.<br>It takes a while for the dark veil to lift, allowing for a <strong>clearer vision</strong>. The gravity of hormonal changes in early pregnancy is often underestimated. These <strong>physical changes</strong> put a strain on your body as well as your emotions. Fortunately, this should level off after a few weeks.</p>',
        1: '<p><strong>Initial Reaction: Shock</strong><br>The news of your pregnancy has been overwhelming. You feel like you are in a state of shock. This is nothing unusual. It is a natural response to such immense news.<br>In addition to the surprise effect, <strong>hormones</strong> are busy wreaking havoc on your emotions.  The <strong>changes your body is undergoing</strong> are not only physically exhausting, but also emotionally draining. Fortunately, this should subside after the first few weeks of pregnancy.</p>',
        2: '<p><strong>Initial Reaction: Joy</strong><br>What a gift to react with joy when finding out about the pregnancy! Even if concerns and worries have surfaced, one’s initial reaction says a lot. Before other things started crowding in, you were able to discern your unfiltered emotions.<br>Allow this part of you to flourish by asking yourself, “What made me respond to the news of pregnancy with joy?”</p>',
        3: '<p><strong>Initial Reaction: Mixed Emotions</strong><br>So many thoughts and emotions are coming at you at once. This is due to make you feel overwhelmed and torn. In early pregnancy, <strong>hormonal</strong> and physical changes can also wreak havoc on your emotions. Their effect is often underestimated.<br>Adjusting physically and mentally to this new situation does not happen overnight. During this phase, emotions tend to fluctuate strongly. Give it a few weeks for your <strong>hormone levels to stabilize</strong>. You will then most likely notice a greater sense of calm, both physically and emotionally.</p>',

      },
    },
    4: {
      question: 'What could my next step be?',
      choices: {
        0: 'I would like to speak to someone who has a neutral perspective.',
        1: 'I would like to confide in someone close to me.',
        2: "I still don't know what I should do.",
        3: "I'm considering taking concrete steps for an abortion",
      },
      advices: {
        0: '<p><strong>Your Next Step: Counsel from a Neutral Party</strong><br>When you find yourself in an emotionally laden situation, receiving neutral counsel can make a huge difference. <strong>Another point of view</strong> can open up new ways of seeing things.<br>If you cannot think of anyone to consult at this time, feel free to make use of our <a href="https://app.profemina.org/en/problems">Problem Solving Test</a></p>',
        1: '<p><strong>Your Next Step: Someone to Talk to</strong><br>Whether you are thinking of your partner, your mom, or a close friend — having someone to <strong>confide in</strong> and <strong>be real with</strong> is priceless in a situation such as this.<br>Our desire is that you would have someone to be <strong>completely open with and</strong> who would <strong>accompany you on the path that is just right for you</strong>.</p>',
        2: '<p><strong>Your Next Step: No Idea?!</strong><br>You can’t see any possible steps to take at this point in time. Would it help to talk to someone about it? Or do you think setting up an appointment with a doctor would bring more clarity? Your gynecologist would be able to tell you how far along you are.<br>If you have a specific concern regarding the pregnancy, you may want to try our <a href="https://app.profemina.org/en/problems">Problem Solving Test</a>.</p>',
        3: '<p><strong>The Next Steps</strong><br>At this point in time, abortion seems like the most obvious way forward and you are planning the next steps in this direction.<br>Maybe you are not completely sure and are also considering options “with baby.” Continue listening to your heart! Paying attention to your emotions will give you the freedom to make a decision that is based on who you really are.</p>',
      },
    },
    5: {
      question: 'Which scenario would help you feel most peaceful in your current situation?',
      choices: {
        0: 'I see a light at the end of the tunnel',
        1: 'I am sitting on my couch at home and feel comfortable to be my true self',
        2: 'I am outdoors, breathing in the fresh air',
        3: 'I am on the top of a mountain and can see everything down below',
        4: 'I am surrounded by loving people who provide support',

      },
      advices: {
        0: '<p><strong>Light at the End of the Tunnel</strong><br>You feel the weight of your worries concerning the pregnancy. It would take a bright light, shining in the distance, to help you take courage, proving that hope is in sight.<br>What tangible things would embody this source of light in your situation? Is there already a shimmer of light on the horizon? How could you help make the light shine brighter?</p>',
        1: '<p><strong>My Place of Solace: Being at Home on My Couch</strong><br>Being pregnant is completely new territory for you, which would naturally feel unnerving. You need a place where you can feel safe and at ease. This vantage point would make everything look a lot less scary.<br>Ask yourself, “Where, or with whom, do I have this sense of being at ease.” What could you do specifically in order to experience more moments of serenity?</p>',
        2: '<p><strong>Being Outdoors</strong><br>Being pregnant feels like a weight that is stifling your breathing and may even make you feel claustrophobic. You experience the outdoors as rejuvenating. Getting outside and breathing some fresh air will most likely open up new perspectives.<br>Is it possible for you to get outdoors regularly? This would make you feel less cramped and allow your concerns to shrink to a more manageable size. What are other ways to reach that sense of serenity in your everyday life? What could you do to create these moments more frequently?</p>',
        3: '<p><strong>On the Mountain Top: Getting a Bird’s Eye View</strong><br>This current situation is probably making you feel trapped. You find it hard to keep everything in perspective. Your many concerns are blocking the bird’s eye view you long to see.<br>Are there moments when everything seems much clearer? Is there anyone who can help you see things more clearly? What are specific things you can do to gain a better perspective?</p>',
        4: '<p><strong>Being Surrounded by Loving People</strong><br>Your concerns about the pregnancy are gnawing away at you and you feel like you are left to deal with them alone. What you long for the most is someone to be your source of hope, strength and courage.<br>Who builds you up from time to time? Could you make a set plan to see them more often?</p>',

      },
    },
    6: {
      question: 'What I desire for the future:',
      choices: {
        0: 'That I can keep my joy in life.',
        1: 'That I can make a decision that will make me feel good in the long run.',
        2: 'That I can be happy about the pregnancy.',
      },
      advices: {
        0: '<p><strong>The Joys of Life</strong><br>You desire the joys in life to be untouched by your decision. This is completely understandable. Discovering beauty in life is what makes it interesting and worth living.<br>What would you identify as the beautiful aspects of your life? What can you do to preserve them? Could this situation be the catalyst for a new and even greater kind of joy?</p>',
        1: '<p><strong>A Good Decision</strong><br>Everyone comes to a crossroads at some point in their life. It is understandable that you would want to be able to <strong>look back on your decision</strong> with no regrets. Unfortunately, the easier road is not always the best route to take.<br>You may already feel confident about the way you are heading. If you desire more <strong>input from a neutral party</strong>, feel free to take the <a href="https://www.profemina.org/en/abortion/abortion-test">Abortion Test</a></p>.',
        2: '<p><strong>Being Happy About the Pregnancy</strong><br>When you just found out about the pregnancy, you may have sensed a hint of joy. But other feelings have now chimed in, which is okay. The more stern emotions <strong>reveal your sense of responsibility</strong>, causing you to consider all eventualities.<br>At the end of the day, <strong>having feelings is not as important</strong> (we don’t really have that much control over them) <strong>as what we do with them</strong>. Emotions usually follow suit when a conscious decision is made.</p>',
      },
    },
  },
};
